.animationload {
  background-color: rgba(0, 0, 0, 0.9);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}
.osahanloading {
  animation: 1.5s linear 0s normal none infinite running osahanloading;
  background: #f1b80e none repeat scroll 0 0;
  border-radius: 50px;
  height: 50px;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
  position: absolute;
  top: 50%;
  width: 50px;
}
.osahanloading::after {
  animation: 1.5s linear 0s normal none infinite running osahanloading_after;
  border-color: #3e627c transparent;
  border-radius: 80px;
  border-style: solid;
  border-width: 10px;
  content: "";
  height: 80px;
  left: -15px;
  position: absolute;
  top: -15px;
  width: 80px;
}
@keyframes osahanloading {
  0% {
    transform: rotate(0deg);
  }
  50% {
    background: #3e627c none repeat scroll 0 0;
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
